import { useState, useEffect, useRef } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgImg, SvgDelete, SvgSave, SvgSetaRight, SvgLink, SvgVimeo, SvgYoutube } from "components/SvgFile";
import { Reg_Site } from "services/RegisterData";

export default function Page_Site_Details(props){
    const sizeVideo = useRef();

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('site'));

    const [ checked, setChecked ] = useState(CheckedInf());

    const [ name, setName ]    = useState(InitialData('name'));    
    const [ title, setTitle ]  = useState(InitialData('title'));
    const [ text, setText ]    = useState(InitialData('text'));
    const [ file, setFile ]    = useState(InitialData('file'));
    const [ status, setStatus] = useState(false);
    
    const [ linkInstagram, setLinkInstagram ] = useState(InitialData('link_instagram'));
    const [ linkFacebook, setLinkFacebook ]   = useState(InitialData('link_facebook'));
    const [ linkLinkedin, setLinkLinkedin ]   = useState(InitialData('link_linkedin'));
    const [ linkWhatsapp, setLinkWhatsapp ]   = useState(InitialData('link_whatsapp'));

    const [ contents, setContents ] = useState(InitialData('contents'));

    function InitialData(type){
        const newData = dataPage.find(item => item.id == idPage);
        return newData[type];
    }

    function CheckedInf(){
        if(GetUserData('access') == 1){
            return true;
        }else {
            const newData = GetUserData('page').find(item => item.page == 'Site');
            if(newData.type == 'Editar'){        
                return true;
            }else {
                return false;
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function Delete(id, index, value){
        if(id == 0){
            const newData = [...contents];
            newData.splice(index, 1);
            setContents(newData);
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "site", "type": "delete_data", "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function NewDataContents(){
        const newData = [...contents];
        newData.push({ "id": 0, "title": "", "subtitle": "", "type_video": "image", "video": "", "status_video": false, "file": "", "status": false, "number": "" });
        setContents(newData);
    }

    function HandleData(type, index, value){
        const newData = [...contents];
        if(type == 'file'){
            if(value){
                newData[index]["file"]   = value;
                newData[index]["status"] = true;
            }else {
                newData[index]["file"]   = '';
                newData[index]["status"] = false;
            }
        }else if(type == 'video'){
            if(value){
                newData[index]["video"]       = value;
                newData[index]["status_video"] = true;
            }else {
                newData[index]["video"]       = '';
                newData[index]["status_video"] = false;
            }
        }
        newData[index][type] = value;
        setContents(newData);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function CheckedSizeFile(index, value){
        let size = sizeVideo.current.files[0].size / 1024 / 1024;
        if(size > 30){
            SetModalData('ReturnResponse', { "page": "missingData", "text": "O arquivo deve ter no máximo 30mb" });
            SetModalState('ReturnResponse', true);
        }else {
            HandleData('video', index, value);
        }
    }

    function ShowDataContents(){
        switch (idPage) {
            // Home
            case 1:
                return(
                    contents.map((elem, index)=>{
                        return(
                            <div className="show_add_block data_page_home" key={ index }>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="" onChange={ (e)=>{ HandleData('type_video', index, e.target.value) } } value={ elem.type_video }>
                                            <option value="image">Imagem</option>
                                            <option value="video">Vídeo</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    {
                                        elem.type_video == "image" ?
                                        <span className="div_input div_add_img">
                                            <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    {
                                                        idPage == 1 ? 
                                                            elem.status == true ? "Foto adicionada" : "Adicionar foto"
                                                        :
                                                            elem.status == true ? "Logotipo adicionadoa" : "Adicionar logotipo"
                                                    }
                                                    {
                                                        elem.file !='' ? 
                                                        <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />: 
                                                        <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" required />
                                                    }
                                                </label>
                                            </div>
                                            {
                                                elem.file !='' ?
                                                <>
                                                    {
                                                        checked ?
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div> : null
                                                    }
                                                    {
                                                        elem.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + elem.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span> :
                                        <span className="div_input div_add_img">
                                            <div className={ elem.status_video == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { elem.status_video == true ? "Vídeo adicionado" : "Adicionar vídeo" }
                                                    {
                                                        elem.video !='' ? 
                                                        <input type="file" ref={ sizeVideo } id="inputFile" onChange={ (e)=>{ CheckedSizeFile(index, e.target.files[0]) } } accept="video/mp4,video/x-m4v,video/*" />: 
                                                        <input type="file" ref={ sizeVideo } id="inputFile" onChange={ (e)=>{ CheckedSizeFile(index, e.target.files[0]) } } accept="video/mp4,video/x-m4v,video/*" required />
                                                    }
                                                </label>
                                            </div>
                                            {
                                                elem.video !='' ?
                                                <>
                                                    {
                                                        checked ?
                                                        <div className="delete_img_add" onClick={ ()=>{ HandleData('video', index, ''); } }>
                                                            <SvgDelete className="icons" color="#ffffff" />
                                                        </div> : null
                                                    }
                                                    {
                                                        elem.status_video == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('video_local', URL.createObjectURL(elem.video)) } }>
                                                            <SvgYoutube color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('video', "site/" + elem.video) } }>
                                                            <SvgYoutube color="#324d6b" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span>
                                    }
                                    <div className="div_input div_icons" onClick={ ()=>{ Delete(elem.id, index, elem.title) } }>
                                        <SvgDelete className="icons" color="#f00000" />
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('subtitle', index, e.target.value) } } value={ elem.subtitle } maxLength="140" />
                                        <span className="name_input">Subtítulo</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) 
            // About
            case 2:
                return(
                    <>
                        <div className="show_data_text about_show_opt">
                            <div className="type_option">Imagem de exemplo 1</div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                                    <span className="name_input">Título</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Foto adicionada" : "Adicionar foto" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                        </label>
                                    </div>
                                    {
                                        file !='' ?
                                        <>
                                            {
                                                checked ?
                                                <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div> : null
                                            }
                                            {
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div show_data_text">
                                    <div className="show_title">Texto</div>
                                    <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                                </div>
                            </div> 
                        </div>

                        <div className="show_data_text about_show_opt">
                            <div className="type_option">Imagem de exemplo 2</div>
                            {
                                contents.map((elem, index)=>{
                                    if(elem.type == 1){
                                        return(
                                            <div className="list_input_data" key={ index }>
                                                <div className="div_input div_number">
                                                    <input type="text" className="input_number" onChange={ (e)=>{ HandleData('number', index, e.target.value) } } value={ elem.number } maxLength="10" />
                                                    <span className="name_input">Número</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                                    <span className="name_input">Título</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className="show_data_text about_show_opt">
                            <div className="type_option">Imagem de exemplo 3</div>
                            {
                                contents.map((elem, index)=>{
                                    if(elem.type == 2 || elem.type == 3){
                                        return(
                                            <div className="list_input_data" key={ index }>
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <span className="div_input div_add_img">
                                                    <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            {
                                                                elem.type == 2 ?
                                                                    elem.status == true ? "Mapa adicionada" : "Adicionar mapa"
                                                                :
                                                                    elem.status == true ? "Ícone adicionada" : "Adicionar ícone"
                                                            }
                                                            <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        elem.file !='' ?
                                                        <>
                                                            {
                                                                checked ? 
                                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                                    <SvgDelete className="icons" color="#ffffff" />
                                                                </div> : null
                                                            }
                                                            {
                                                                elem.status == true ?
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div> :
                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + elem.file) } }>
                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                </div>
                                                            }
                                                        </>
                                                        : null
                                                    }
                                                </span>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>

                        <div className="show_data_text about_show_opt">
                            <div className="type_option">Imagem de exemplo 4</div>
                            {
                                contents.map((elem, index)=>{
                                    if(elem.type == 4){
                                        return(
                                            <div className="list_input_data" key={ index }>
                                                <div className="div_input div_number">
                                                    <input type="text" className="input_number" onChange={ (e)=>{ HandleData('number', index, e.target.value) } } value={ elem.number } maxLength="10" />
                                                    <span className="name_input">Número</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('subtitle', index, e.target.value) } } value={ elem.subtitle } maxLength="140" />
                                                    <span className="name_input">Subtítulo</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </>
                )
            // Client
            case 3:
                return(
                    contents.map((elem, index)=>{
                        return(
                            <div className="show_add_block" key={ index }>
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                    <span className="name_input">Nome do cliente</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            {
                                                elem.status == true ? "Logotipo adicionadoa" : "Adicionar logotipo"
                                            }
                                            {
                                                elem.file !='' ? 
                                                <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />: 
                                                <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" required />
                                            }
                                        </label>
                                    </div>
                                    {
                                        elem.file !='' ?
                                        <>
                                            {
                                                checked ?
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div> : null
                                            }
                                            {
                                                elem.status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "site/" + elem.file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            }
                                        </>
                                        : null
                                    }
                                </span>
                                {
                                    checked ?
                                    <div className="div_input div_icons" onClick={ ()=>{ Delete(elem.id, index, elem.title) } }>
                                        <SvgDelete className="icons" color="#f00000" />
                                    </div> : null
                                }
                            </div>
                        )
                    })
                )
            // Contact
            case 4:
                return(
                    <>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                                <span className="name_input">E-mail</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="show_title">Texto</div>
                                <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                            </div>
                        </div> 

                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setLinkInstagram(e.target.value) } } value={ linkInstagram } />
                                <span className="name_input">Link instagram</span>
                            </div>
                            {
                                linkInstagram == "" ? null :
                                <div className="div_input div_icons">
                                    <a href={ linkInstagram } target="_blank">
                                        <SvgLink className="icons" color="#2D3C53" />
                                    </a>
                                </div>
                            }
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setLinkFacebook(e.target.value) } } value={ linkFacebook } />
                                <span className="name_input">Link facebook</span>
                            </div>
                            {
                                linkFacebook == "" ? null :
                                <div className="div_input div_icons">
                                    <a href={ linkFacebook } target="_blank">
                                        <SvgLink className="icons" color="#2D3C53" />
                                    </a>
                                </div>
                            }
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setLinkLinkedin(e.target.value) } } value={ linkLinkedin } />
                                <span className="name_input">Link linkedin</span>
                            </div>
                            {
                                linkLinkedin == "" ? null :
                                <div className="div_input div_icons">
                                    <a href={ linkLinkedin } target="_blank">
                                        <SvgLink className="icons" color="#2D3C53" />
                                    </a>
                                </div>
                            }
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setLinkWhatsapp(e.target.value) } } value={ linkWhatsapp } />
                                <span className="name_input">Link whatsapp</span>
                            </div>
                            {
                                linkWhatsapp == "" ? null :
                                <div className="div_input div_icons">
                                    <a href={ linkWhatsapp } target="_blank">
                                        <SvgLink className="icons" color="#2D3C53" />
                                    </a>
                                </div>
                            }
                        </div>
                    </>
                )
        }
    }

    function TypePage(){
        switch (idPage) {
            case 1:
                return(
                    <div className="show_img">
                        <img alt="example" src="./assets/img_1.jpg" className="img_example" />
                    </div>
                )
                
            case 2:
                return(
                    <div className="show_img">
                        <img alt="example" src="./assets/img_2.jpg" className="img_example" />
                    </div>
                )
                
            case 3:
                return(
                    <div className="show_img">
                        <img alt="example" src="./assets/img_3.jpg" className="img_example" />
                    </div>
                )
                
            case 4:
                return(
                    <div className="show_img">
                        <img alt="example" src="./assets/img_4.jpg" className="img_example" />
                    </div>
                )
        }
    }

    function ReturnPage(){
        SetListPag('currentPage', 'site');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Site(userId, idPage, title, text, file, linkFacebook, linkInstagram, linkLinkedin, linkWhatsapp, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPageId', setIdPage);
        
        RegisterDataPage('site', setDataPage);
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));        
        setDataPage(GetDataPage('site'));

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setFile(InitialData('file'));
        setStatus(false);

        setLinkInstagram(InitialData('link_instagram'));
        setLinkFacebook(InitialData('link_facebook'));
        setLinkLinkedin(InitialData('link_linkedin'));
        setLinkWhatsapp(InitialData('link_whatsapp'));

        setContents(InitialData('contents'));
    }, [dataPage, idPage]);

    return(
        <div className="Page_Site_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Voltar
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        {
                            checked ? 
                            <button className="div_add_new_data">
                                <div className="new_data_add">
                                    <SvgSave color="#ffffff" className="icons" />
                                </div>
                                <div className="name_btn_add">salvar</div>
                            </button> : null
                        }
                    </div>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Página - { name }</div>
                        {
                            checked ?
                            idPage != 1 && idPage != 3 ? null :
                            <div className="new_data_page" onClick={ ()=>{ NewDataContents() } }>
                                {
                                    idPage == 1 ? "Adicionar novo slide" : "Adicionar novo cliente"
                                }                                
                            </div> : null
                        }
                    </div>
                    { ShowDataContents() }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Modelo final da página</div>
                    </div>
                    <div className="list_input_data">
                        { TypePage() }
                    </div>
                </div>
            </form>
        </div>
    )
}