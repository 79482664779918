import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_UserDash } from "services/RegisterData";

export default function Page_Users_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("access_dash"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]     = useState(InitialData('name'));
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ pass, setPass ]     = useState('');
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ nivel, setNivel ]   = useState(InitialData('nivel'));
    const [ note, setNote ]     = useState(InitialData('note'));
    const [ page, setPage ]     = useState(InitialData('page'));
    const [ status, setStatus ] = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'nivel'){
            return 0;
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...page];
            newData.splice(index, 1);
            setPage(newData);
        }else {
            SetModalData('Confirmation', { "origin": "access_dash", "type" : "delete_page", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function AddNewAccess(){
        const newData = [...page];
        newData.push({ "id": 0, "type": "Apenas visualizar", "page": "" });
        setPage(newData);
    }

    function HandleData(type, index, value){
        const newData = [...page];
        newData[index][type] = value;
        setPage(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, page, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() }, ()=>{ CallbackEmail() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackEmail(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": 'Já tem um cadastro utilizado o e-mail "' + email + '"!' });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_dash');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setDataPage);

        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("access_dash"));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPass('');
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setStatus(false);
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Users_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Voltar
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Usuários
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            {
                                idPage !=0 ?
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } /> :
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" required />
                            }
                            <span className="name_input">Senha</span>
                        </div>
                        {
                            idPage == userId ? null :
                            <div className="div_input">
                                <select className="data_select" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                    <option value="0">Usuário</option>
                                    <option value="1">Administrador</option>
                                </select>
                                <span className="name_input">Acesso</span>
                            </div>
                        }
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>

                {
                    nivel == 1 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Acesso as páginas</div>
                            <div className="new_data_page" onClick={ ()=>{ AddNewAccess(); } }>
                                Adicionar página
                            </div>
                        </div>
                        <div className="list_name_page">
                            {
                                page.length > 0 ?
                                    page.map((elem, index)=>{
                                        return(
                                            <div className="div_name_page_access" key={ index }>
                                                <div className="div_input">
                                                    {
                                                        elem.id !=0 ? 
                                                        <input type="text" className="input_name_page" value={ elem.name } readOnly /> :
                                                        <select className="" onChange={ (e)=>{ HandleData('page', index, e.target.value) } } value={ elem.page } required>
                                                            <option value="">#</option>
                                                            <option value="Site/0">Site</option>
                                                            <option value="Produtos/1">Produtos</option>
                                                            <option value="Painel/2">Painel</option>
                                                            <option value="Veiculação/3">Veiculação</option>
                                                        </select>
                                                    }
                                                    <span className="name_input">Página</span>
                                                </div>
                                                <div className="div_input">
                                                    <select className="" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ elem.type }>
                                                        <option value="Editar">Editar</option>
                                                        <option value="Apenas visualizar">Apenas visualizar</option>
                                                    </select>
                                                    <span className="name_input">Tipo</span>
                                                </div>
                                                <div className="" onClick={ ()=>{ DeleteData(elem.id, index, elem.name) } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                <div className="no_data">Nenhuma página liberada até o momento...</div>
                            }
                        </div>
                    </div>
                }
            </form>
        </div>
    )
}