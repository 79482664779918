import { useState, useEffect } from "react";

import './Details.css';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgDelete, SvgSave, SvgSetaRight } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Reg_Broadcast } from "services/RegisterData";

export default function Page_Broadcast_Details(props){
    const dateYear = new Date();

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('broadcast'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ checked, setChecked ] = useState(CheckedInf());

    const [ title, setTitle ]       = useState(InitialData('title'));
    const [ year, setYear ]         = useState(InitialData('year'));
    const [ listWeek, setListWeek ] = useState(InitialData('list_week'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            console.log(newData);
            return newData[type];
        }
        if(type == 'year'){
            return dateYear.getFullYear();
        }
        if(type == 'list_week'){            
            const newData = [];
            for (let i = 1; i <= 52; i++) {
                if(i % 2 == 0){
                    newData.push({ "id": 0, "week": i, "date_start": year + "-" + getDaysOfWeek(i - 1, year, 0), "date_end": year + "-" + getDaysOfWeek(i, year, 6) });
                }
            }
            return newData;
        }
        return '';
    }

    function CheckedInf(){
        if(GetUserData('access') == 1){
            return true;
        }else {
            const newData = GetUserData('page').find(item => item.page == 'Veiculação');
            if(newData.type == 'Editar'){        
                return true;
            }else {
                return false;
            }
        }
    }

    function AllDateStartAndEnd(){
        const newData = [];
        for (let i = 1; i <= 52; i++) {
            if(i % 2 == 0){
                newData.push({ "id": 0, "week": i, "date_start": year + "-" + getDaysOfWeek(i - 1, year, 0), "date_end": year + "-" + getDaysOfWeek(i, year, 6) });
            }
        }
        setListWeek(newData);
    }

    function getDaysOfWeek(weekNumber, year, type) {
        const daysOfWeek = [];
        const date = new Date();
        date.setUTCFullYear(year);
        date.setUTCMonth(0);
        date.setUTCDate(1);
        const firstDayOfYear = 1;
        const daysInYear = isLeapYear(date.getUTCFullYear()) ? 366 : 365;
        const daysInWeek = 7;
        const daysToWeek = (weekNumber - 1) * daysInWeek + firstDayOfYear;
        const daysFromWeek = daysInYear - daysToWeek;
        const daysToAdd = daysInWeek - daysFromWeek % daysInWeek;

        date.setUTCDate(date.getUTCDate() + daysToWeek);
        for (let i = 0; i < daysInWeek; i++) {
            daysOfWeek.push(new Date(date.getTime()));
            date.setUTCDate(date.getUTCDate() + 1);
        }

        let month = "";
        if((daysOfWeek[type].getUTCMonth() + 1) > 9){
            month = (daysOfWeek[type].getUTCMonth() + 1);
        }else {
            month = "0" + (daysOfWeek[type].getUTCMonth() + 1);
        }

        let day   = "";
        if(daysOfWeek[type].getUTCDate() > 9){
            day = daysOfWeek[type].getUTCDate();
        }else {
            day = "0" + daysOfWeek[type].getUTCDate();
        }
        return month + "-" + day;
    }

    function isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    function HandleData(type, index, value){
        const newData = [...listWeek];
        newData[index][type] = value;
        setListWeek(newData);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'broadcast');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Broadcast(userId, idPage, title, year, listWeek, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('broadcast', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('broadcast'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setYear(InitialData('year'));
        setListWeek(InitialData('list_week'));
    }, [dataPage, idPage]);

    return(
        <div className="Page_Broadcast_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Veiculação
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        {
                            checked ?
                            <button className="div_add_new_data">
                                <div className="new_data_add">
                                    <SvgSave color="#ffffff" className="icons" />
                                </div>
                                <div className="name_btn_add">salvar</div>
                            </button> : null
                        }
                    </div>
                </div>
                
                <div className="show_data_and_example">
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Lista das datas</div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_input">
                                <input type="text" className="year" onChange={ (e)=>{ setYear(e.target.value) } } value={ year } maxLength="4" required />
                                <span className="name_input">Ano</span>
                            </div>
                        </div>
                        {
                            listWeek.map((elem, index)=>{
                                return(
                                    <div className="list_input_data border_date" key={ index }>
                                        <div className="div_input">
                                            <input type="text" className="week" value={ elem.week } readOnly />
                                            <span className="name_input">Semana</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="date" className="space_div" onChange={ (e)=>{ HandleData('date_start', index, e.target.value) } } value={ elem.date_start } required />
                                            <span className="name_input">Data início</span>
                                        </div>
                                        <div className="div_input space_div">
                                            <input type="date" className="space_div" onChange={ (e)=>{ HandleData('date_end', index, e.target.value) } } value={ elem.date_end } required />
                                            <span className="name_input">Data final</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="show_page_data div_pdf_example">
                        <div className="list_type_data">
                            <div className="type_title">Exemplo do pdf gerado</div>
                        </div>
                        <div className="list_input_data">
                            <div className="list_example_pdf">
                                <div className="show_img" style={ { backgroundImage: "url('./assets/bg.jpg')" } }>
                                    <div className="div_logotipo">
                                        <img alt="logo" src="./assets/logo_2.png" className="logotipo" />
                                    </div>
                                    <div className="subtitle">Bi-semanas</div>
                                    <div className="title">{ year }</div>
                                </div>
                                <div className="div_week">
                                    <div className="div_list_week">
                                        {
                                            idPage == 0 ? "As datas serão listadas aqui após salvar os dados" :
                                            <>
                                                <div className="show_week">
                                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                                        <tbody>
                                                            <tr>
                                                                <td className="week_type title">Bi-semana</td>
                                                                <td className="week_show title">Veiculação</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody>
                                                            {
                                                                listWeek.map((elem, index)=>{
                                                                    if(index <= 12){
                                                                        return(
                                                                            <tr key={ index }>
                                                                                <td className="week_type" align="center">
                                                                                    <div className="color_week">
                                                                                        { elem.week }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="week_show">
                                                                                    <div className="color_date">
                                                                                        { elem.date_start_br } à { elem.date_end_br }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="show_week">
                                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                                        <tbody>
                                                            <tr>
                                                                <td className="week_type title">Bi-semana</td>
                                                                <td className="week_show title">Veiculação</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody>
                                                            {
                                                                listWeek.map((elem, index)=>{
                                                                    if(index > 12){
                                                                        return(
                                                                            <tr key={ index }>
                                                                                <td className="week_type" align="center">
                                                                                    <div className="color_week">
                                                                                        { elem.week }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="week_show">
                                                                                    <div className="color_date">
                                                                                        { elem.date_start_br } à { elem.date_end_br }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        }                                        
                                    </div>
                                </div>
                                <div className="div_contact">
                                    <div className="title">Contato</div>
                                    <div className="div_data">
                                        <div className="show_data_contact">
                                            <div className="div_icon">
                                                <img alt="phone" src="./assets/phone.png" className="img_icon" />
                                            </div>
                                            <div className="div_name">(75) 98204-2020</div>
                                        </div>
                                        <div className="show_data_contact">
                                            <div className="div_icon">
                                                <img alt="phone" src="./assets/whatsapp.png" className="img_icon" />
                                            </div>
                                            <div className="div_name">(71) 99172-9849</div>
                                        </div>
                                    </div>
                                    <div className="div_data">
                                        <div className="show_data_contact">
                                            <div className="div_icon">
                                                <img alt="phone" src="./assets/email.png" className="img_icon" />
                                            </div>
                                            <div className="div_name">contato@maxmidiaooh.com.br</div>
                                        </div>
                                        <div className="show_data_contact">
                                            <div className="div_icon">
                                                <img alt="phone" src="./assets/site.png" className="img_icon" />
                                            </div>
                                            <div className="div_name">
                                                <a href="https://maxmidiaooh.com.br/" target="_blank">
                                                    www.maxmidiaooh.com.br
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}