import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_Delete } from "services/RegisterData";

import { GetUserData, RegisterUserData } from "interface/Users";

export default function PopUP_Confirmation(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    function TypeAlt(){
        modalData.setLoading(true);
        Reg_Delete(userId, modalData.id, modalData.origin, modalData.type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        modalData.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
    }

    function CallbackError(){
        modalData.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
    }

    function ClosePopUp(){
        modalData.setLoading(false);
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "disabled_access_dash":
                return (
                    <div>
                        Desativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            case "reactivate_access_dash":
                return (
                    <div>
                        Reativar usuário:
                        <div>{ modalData.name }</div>
                    </div>
                );

            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all Confirmation">
                        <div className="div_data">
                            <div className="title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content div_confirmation">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
