import { useState, useEffect } from "react";

import './Site.css';

import { SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgEdit } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Site(props){

    const [ showPageData, setShowPageData ] = useState(GetDataPage('site'));

    function PageClick(idPage){
        SetListPag('currentPage', 'site_details')
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterDataPage('site', setShowPageData);
    }, []);

    return(
        <div className="Page_Products">
            <div className="new_block">
                <div className="title_page">Site</div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Página</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            showPageData.map((elem, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div data-tooltip-id={ "edi_" + elem.id } data-tooltip-content="Editar Página" data-tooltip-place="top" onClick={ ()=>{ PageClick(elem.id) } } title="Editar produto">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}